.ratingReviews {
    position: relative;
    padding-bottom: 310px;
    margin-bottom: 110px;
    &__inner {
        position: relative;
        z-index: 1;
    }
    &__title {
        margin-bottom: 44px;
        font-size: 30px;
        font-weight: 800;
        text-align: center;
        color: rgb(var(--blackRock));
    }
    &__companies {
        margin-bottom: 84px;
    }
    &__posts {
        margin-bottom: 102px;
    }
    &__bg {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        img {
            width: inherit;
        }
    }
}

@media (max-width: 1080px) {
    .ratingReviews {
        padding-bottom: 210px;
        margin-bottom: 60px;
    }
}

@media (max-width: 767px) {
    .ratingReviews {
        padding-bottom: 150px;
        overflow: hidden;
        &__posts {
            margin-bottom: 54px;
        }
        &__bg {
            left: calc(50% - 384px);
            width: 768px;
        }
    }
}